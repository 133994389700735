import { FC, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks';
import { PivotTableProps } from './types';
import { loadSummaryData } from 'store/income';
import { Loader, ErrorPage } from 'components';
import { getFormatIncome } from 'utils';

import './PivotTable.scss';

export const PivotTable: FC<PivotTableProps> = () => {
  const dispatch = useAppDispatch();
  // TODO: use createSelector
  const { data, isLoading, isError } = useAppSelector((store) => store.income.pivotTable);

  useEffect(() => {
    dispatch(loadSummaryData());
  }, [dispatch]);

  const totalIncomeNow = data.reduce((acc, { currentIncome }) => {
    if (currentIncome) {
      acc += currentIncome;
    }
    return acc;
  }, 0);

  const totalPlannedIncome = data.reduce((acc, { estimatedIncome }) => {
    if (estimatedIncome) {
      acc += estimatedIncome;
    }
    return acc;
  }, 0);

  return (
    <div className="pivot-table">
      {isError ? (
        <ErrorPage />
      ) : (
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className="pivot-table__left-cell" align="center" />
                <TableCell align="center" sx={{ fontWeight: '700' }}>
                  Сейчас
                  <br />+{getFormatIncome(totalIncomeNow)}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: '700' }}>
                  Планируемый
                  <br />+{getFormatIncome(totalPlannedIncome)}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <Loader />
              ) : (
                data.map((item) => (
                  <TableRow className="table-row" key={item.summaryName}>
                    <TableCell className="pivot-table__left-cell" align="left">
                      {item.summaryName}
                    </TableCell>
                    <TableCell align="center">+{getFormatIncome(item.currentIncome)}</TableCell>
                    <TableCell align="center">+{getFormatIncome(item.estimatedIncome)}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};
