import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addIncome, deleteIncome, loadIncomes, loadSummaryData } from './thunk';
import { IncomeForm, IncomeRootState, MonthsData } from './types';

const initialState: IncomeRootState = {
  incomeNow: {
    selectedMonth: {
      monthId: new Date().getMonth(),
      year: new Date().getFullYear(),
      isEstimated: false,
    },
    isLoading: false,
    isError: false,
    data: [],
    monthsData: [],
    form: {
      sum: '',
      source: '',
      date: null,
      description: '',
      monthId: null,
      year: null,
      isEstimated: false,
    },
  },
  plannedIncome: {
    selectedMonth: {
      monthId: new Date().getMonth(),
      year: new Date().getFullYear(),
      isEstimated: true,
    },
    isLoading: false,
    isError: false,
    data: [],
    monthsData: [],
    form: {
      sum: '',
      source: '',
      date: null,
      description: '',
      monthId: null,
      year: null,
      isEstimated: true,
    },
  },
  pivotTable: {
    data: [],
    isLoading: false,
    isError: false,
  },
};

export const incomeSlice = createSlice({
  name: 'income',
  initialState,
  reducers: {
    changeSelectedMonth(
      state,
      { payload }: PayloadAction<{ tableName: 'incomeNow' | 'plannedIncome'; value: MonthsData }>,
    ) {
      state[payload.tableName].selectedMonth = payload.value;
    },

    changeIncomeNowForm(state, { payload }: PayloadAction<IncomeForm>) {
      state.incomeNow.form = { ...state.incomeNow.form, ...payload };
    },

    changePlannedIncomeForm(state, { payload }: PayloadAction<IncomeForm>) {
      state.plannedIncome.form = { ...state.plannedIncome.form, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadIncomes.rejected, (state, { meta }) => {
        if (!meta.arg.isEstimated) {
          state.incomeNow.isError = true;
          return;
        }
        state.plannedIncome.isError = true;
      })
      .addCase(loadIncomes.pending, (state, { meta }) => {
        if (!meta.arg.isEstimated) {
          state.incomeNow.isLoading = true;
          return;
        }
        state.plannedIncome.isLoading = true;
      })
      .addCase(loadIncomes.fulfilled, (state, { payload: { data }, meta }) => {
        if (!meta.arg.isEstimated) {
          state.incomeNow.data = data;
          state.incomeNow.isLoading = false;
          return;
        }
        state.plannedIncome.data = data;
        state.plannedIncome.isLoading = false;
      })

      // .addCase(addIncome.rejected, (state, action) => {
      //   console.log('addIncome.rejected', action);
      // })
      // .addCase(addIncome.pending, (state, action) => {
      //   console.log('addIncome.pending', action);
      // })
      .addCase(addIncome.fulfilled, (state, { payload, meta }) => {
        if (!meta.arg.isEstimated) {
          state.incomeNow.data.unshift(payload);
          state.incomeNow.form = initialState.incomeNow.form;
          return;
        }
        state.plannedIncome.data.unshift(payload);
        state.plannedIncome.form = initialState.plannedIncome.form;
      })

      // .addCase(deleteIncome.rejected, (state, action) => {
      //   // TODO
      // })
      // .addCase(deleteIncome.pending, (state, action) => {
      //   // TODO
      // })
      .addCase(deleteIncome.fulfilled, (state, { payload, meta }) => {
        if (!meta.arg.isEstimated) {
          const filteredData = state.incomeNow.data.filter((item) => item.UID !== payload);
          state.incomeNow.data = filteredData;
          return;
        }
        const filteredData = state.plannedIncome.data.filter((item) => item.UID !== payload);
        state.plannedIncome.data = filteredData;
      })

      .addCase(loadSummaryData.rejected, (state) => {
        state.pivotTable.isError = true;
      })
      .addCase(loadSummaryData.pending, (state) => {
        state.pivotTable.isLoading = true;
      })
      .addCase(loadSummaryData.fulfilled, (state, { payload }) => {
        state.pivotTable.data = payload;
        state.pivotTable.isLoading = false;
      });
  },
});

export const { changeIncomeNowForm, changePlannedIncomeForm, changeSelectedMonth } =
  incomeSlice.actions;

export default incomeSlice;
