import { MonthsData } from 'store/income/types';

export const formatDate = (date: string) => {
  const inputDateTime = new Date(date);

  const localDateTimeString = inputDateTime.toLocaleString('ru-RU', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Europe/Moscow',
  });

  return localDateTimeString.replace(',', '');
};

export const getFormatIncome = (income: number | null) => {
  return new Intl.NumberFormat('ru-RU').format(income || 0);
};

export const getFilter = (selectedMonth: MonthsData) => {
  const { isEstimated, monthId, year } = selectedMonth;
  return `/api/incomes?isEstimated=${isEstimated}&monthId=${monthId}&year=${year}`;
};
