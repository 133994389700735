import { CircularProgress, LinearProgress } from '@mui/material';

import './Loader.scss';

export const Loader = () => {
  return (
    <div className="loader">
      Пожалуйста, подождите,
      <br />
      идет загрузка данных...
      <CircularProgress />
    </div>
  );
};
