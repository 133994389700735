import { FC, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow as TableRowMUI,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks';

import { TableRow, FormRow, MonthSelect, Loader } from 'components';
import { IncomeTableProps } from './types';
import { addIncome, changeIncomeNowForm, changePlannedIncomeForm, loadIncomes } from 'store/income';
import { getFilter } from 'utils';

import './IncomeTable.scss';

export const IncomeTable: FC<IncomeTableProps> = ({ tableName }) => {
  const dispatch = useAppDispatch();
  const { data, form, selectedMonth, isLoading } = useAppSelector(
    (store) => store.income[tableName],
  );

  const totalIncome = data.reduce((acc, item) => acc + item.sum, 0);
  const formatIncome = new Intl.NumberFormat('ru-RU').format(totalIncome);

  useEffect(() => {
    if (selectedMonth) {
      dispatch(
        loadIncomes({ filter: getFilter(selectedMonth), isEstimated: selectedMonth.isEstimated }),
      );
    }
  }, [dispatch, selectedMonth]);

  const handleSaveInfo = () => {
    if (!form.sum || !form.date || !selectedMonth) {
      return;
    }

    dispatch(
      addIncome({
        ...form,
        monthId: selectedMonth?.monthId,
        year: selectedMonth?.year,
      }),
    );
  };

  return (
    <div className="income-table">
      <MonthSelect tableName={tableName} />

      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRowMUI>
              <TableCell align="center">Доход</TableCell>
              <TableCell align="center">Источник</TableCell>
              <TableCell align="center">Дата и время</TableCell>
              <TableCell align="center">Примечание</TableCell>
              <TableCell align="center" />
            </TableRowMUI>
          </TableHead>

          <TableBody>
            <TableRowMUI>
              <TableCell align="center" className="income-table__total-income">
                +{formatIncome}
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRowMUI>

            <FormRow
              form={form}
              changeForm={tableName === 'incomeNow' ? changeIncomeNowForm : changePlannedIncomeForm}
              handleSaveInfo={handleSaveInfo}
            />

            {isLoading ? (
              <Loader />
            ) : (
              data.map((row) => <TableRow tableName={tableName} row={row} key={row.UID} />)
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
