import { createSlice } from '@reduxjs/toolkit';
import { changePassword, loadUser, userLogin, userLogout, userRegistration } from './thunk';
import { UserState } from './types';

const initialState: UserState = {
  userInfo: null,
  registrationErrorMsg: null,
  registrationMsg: null,
  loginErrorMsg: null,
  changePasswordMsg: null,
  changePasswordError: null,
  loading: false,
  error: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearState(state) {
      state.loginErrorMsg = null;
      state.registrationErrorMsg = null;
      state.registrationMsg = null;
      state.changePasswordMsg = null;
      state.changePasswordError = null;
      state.userInfo = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userRegistration.rejected, (state, action) => {
        state.registrationErrorMsg = action.error.message as string;
      })
      .addCase(userRegistration.fulfilled, (state, action) => {
        state.registrationErrorMsg = null;
        state.registrationMsg = action.payload;
      })

      .addCase(changePassword.rejected, (state, action) => {
        state.changePasswordMsg = null;
        state.changePasswordError = action.error.message as string;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.changePasswordError = null;

        if (action.payload.isPasswordChangeSuccessful) {
          state.changePasswordMsg = 'Пароль быль успешно изменен';
        }

        if (action.payload.isMailSuccessful) {
          state.changePasswordMsg =
            'На вашу почту было отправлено сообщение. Пожалуйста, следуйте инструкции в письме для восстановления пароля';
        }
      })

      .addCase(userLogin.rejected, (state, action) => {
        state.loginErrorMsg = action.error.message as string;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        if (!action.payload.isActive) {
          state.loginErrorMsg = `Аккаунт не активирован. На вашу почту была отправлена ссылка для активации.`;
        }
        state.userInfo = action.payload;
      })

      .addCase(loadUser.rejected, (state) => {
        state.error = false;
      })
      .addCase(loadUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadUser.fulfilled, (state, action) => {
        state.userInfo = action.payload;
        state.error = false;
        state.loading = false;
      })

      // .addCase(userLogout.rejected, (state, action) => {
      //   // TODO
      // })
      .addCase(userLogout.fulfilled, (state) => {
        state.userInfo = null;
      });
  },
});

export const { clearState } = userSlice.actions;

export default userSlice;
