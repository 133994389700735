import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { AuthPage, ChangePasswordPage, WelcomePage, MainPage } from 'pages';
import { loadUser } from 'store/user';
import { ErrorPage, Layout } from 'components';

export const App = () => {
  const dispatch = useAppDispatch();
  const { userInfo, loading, error } = useAppSelector((store) => store.user);
  const { isAuthenticated, isActive } = userInfo || {};

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  if (loading) {
    return null;
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <Routes>
      {isAuthenticated && isActive ? (
        <>
          <Route path="/" element={<Layout />}>
            <Route index element={<WelcomePage />} />
          </Route>
          <Route path="/main" element={<MainPage />} />
          <Route path="*" element={<Navigate to="/main" replace />} />
        </>
      ) : (
        <Route path="/" element={<Layout />}>
          <Route index element={<WelcomePage />} />
          <Route path="/login" element={<AuthPage />} />
          <Route path="/change-password" element={<ChangePasswordPage />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Route>
      )}
    </Routes>
  );
};
