import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { incomeSlice } from './income';
import { userSlice } from './user';

const rootReducer = combineReducers({
  [incomeSlice.name]: incomeSlice.reducer,
  [userSlice.name]: userSlice.reducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;

export type AppDispatch = AppStore['dispatch'];
