import { useEffect, useState } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { DONATE_URL, TG_URL } from 'api';

import './Layout.scss';

export const Layout = () => {
  const navigate = useNavigate();

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="layout" style={{ height: windowHeight }}>
      <div className="layout__header">
        <Link to="/">Доход — состояние</Link>

        <Button
          className="layout__btn"
          type="submit"
          variant="contained"
          onClick={() => navigate('/login')}
        >
          Перейти в доход
        </Button>
      </div>

      <div className="layout__main">
        <Outlet />
      </div>

      <div className="layout__footer">
        <div>
          О моей работе с состоянием можно почитать в{' '}
          <a target="_blank" href={TG_URL} rel="noreferrer">
            канале
          </a>
        </div>

        <div>
          Инструмент бесплатный. <br />
          Если вспоминаешь о нём с благодарностью, то подкрепи любой суммой, ссылка{' '}
          <a target="_blank" href={DONATE_URL} rel="noreferrer">
            тут.
          </a>
          <br />
          Благодарность - это не обязательство, а состояние, из которого хочется отдавать и тогда
          возвращается в несколько крат.
        </div>
      </div>
    </div>
  );
};
