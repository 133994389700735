import './ErrorPage.scss';

export const ErrorPage = () => {
  return (
    <div className="error-page">
      Произошла ошибка. <br />
      Пожалуйста, обновите страницу
    </div>
  );
};
