import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Button } from '@mui/material';
import { changeSelectedMonth } from 'store/income';
import { MonthSelectProps } from './types';

import './MonthSelect.scss';

const MONTHS_ARRAY = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export const MonthSelect: FC<MonthSelectProps> = ({ tableName }) => {
  const dispatch = useAppDispatch();
  const { selectedMonth } = useAppSelector((store) => store.income[tableName]);

  const handleChangeYear = (year: number) => {
    dispatch(
      changeSelectedMonth({
        tableName,
        value: {
          ...selectedMonth,
          year,
        },
      }),
    );
  };

  const handleChangeMonth = (monthId: number) => {
    dispatch(
      changeSelectedMonth({
        tableName,
        value: {
          ...selectedMonth,
          monthId,
        },
      }),
    );
  };

  return (
    <div className="month-select">
      <div style={{ padding: '8px 8px 4px', display: 'flex' }}>
        {[2024, 2025, 2026, 2027, 2028].map((item) => (
          <Button
            key={item}
            variant="contained"
            onClick={() => handleChangeYear(item)}
            className={selectedMonth?.year === item ? 'year-btn selected' : 'year-btn'}
          >
            {item}
          </Button>
        ))}
      </div>

      <div style={{ minWidth: 120, padding: '0 8px 8px 8px', display: 'flex' }}>
        {MONTHS_ARRAY.map((item) => (
          <Button
            key={item}
            variant="contained"
            className={selectedMonth?.monthId === item ? 'month-btn selected' : 'month-btn'}
            onClick={() => handleChangeMonth(item)}
          >
            {item + 1}
          </Button>
        ))}
      </div>
    </div>
  );
};
