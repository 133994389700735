import { FormEvent, useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { FormData } from 'components/RegistrationForm/types';
import { changePassword, clearState } from 'store/user';

export const ChangePasswordPage = () => {
  const dispatch = useAppDispatch();
  const { changePasswordMsg, changePasswordError } = useAppSelector((store) => store.user);
  let [params] = useSearchParams();
  const token = params.get('rt');

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatedPassword, setShowRepeatedPassword] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as typeof e.target & FormData;
    let data;

    if (token) {
      data = {
        password: target.password.value,
        repeatedPassword: target.repeatedPassword.value,
        restoreToken: token,
        action: 'change_password',
      };
    } else {
      data = {
        email: target.email.value,
        action: 'send_link',
      };
    }

    dispatch(changePassword(data));
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  if (token) {
    return (
      <div className="auth-page">
        {changePasswordMsg ? (
          <div className="success-message">
            {changePasswordMsg}
            <Link to="/login">Перейти на страницу входа</Link>
          </div>
        ) : (
          <form className="login-form" onSubmit={handleSubmit}>
            <h2>Восстановление пароля</h2>

            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
              <InputLabel htmlFor="password">Пароль</InputLabel>
              <OutlinedInput
                id="password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((show) => !show)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Пароль"
              />
            </FormControl>

            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
              <InputLabel htmlFor="repeatedPassword">Повторите пароль</InputLabel>
              <OutlinedInput
                id="repeatedPassword"
                type={showRepeatedPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowRepeatedPassword((show) => !show)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showRepeatedPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Повторите пароль"
              />
            </FormControl>

            {changePasswordError && <div className="error-message">{changePasswordError}</div>}

            <Button type="submit" variant="contained">
              Отправить
            </Button>
          </form>
        )}
      </div>
    );
  }

  return (
    <div className="auth-page">
      <form className="login-form" onSubmit={handleSubmit}>
        <h2>Восстановление пароля</h2>

        <p>
          Укажите свой email, под которым вы зарегистрированы на сайте и на него будет отправлена
          информация о восстановлении пароля
        </p>

        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
          <InputLabel htmlFor="email">Email</InputLabel>
          <OutlinedInput id="email" type="email" label="email" />
        </FormControl>

        {changePasswordError && <div className="error-message">{changePasswordError}</div>}
        {changePasswordMsg && <div className="success-message">{changePasswordMsg}</div>}

        <Button type="submit" variant="contained">
          Отправить
        </Button>
      </form>
    </div>
  );
};
