import { FC } from 'react';
import { TableCell, TableRow as TableRowMUI } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useAppDispatch } from 'hooks';
import { TableRowProps } from './types';
import { formatDate } from 'utils';
import { deleteIncome } from 'store/income';

import './TableRow.scss';

export const TableRow: FC<TableRowProps> = ({ tableName, row }) => {
  const dispatch = useAppDispatch();

  const handleDelete = (id: string) => {
    dispatch(deleteIncome({ id, isEstimated: tableName === 'incomeNow' ? false : true }));
  };

  const income = new Intl.NumberFormat('ru-RU').format(row.sum);

  return (
    <TableRowMUI className="table-row" key={row.UID}>
      <TableCell align="center">+{income}</TableCell>
      <TableCell align="center">{row.source}</TableCell>
      <TableCell className="form-row__date" align="center">
        {formatDate(row.date)}
      </TableCell>
      <TableCell align="center">{row.description}</TableCell>
      <TableCell align="center">
        <ClearIcon titleAccess="Удалить" fontSize="small" onClick={() => handleDelete(row.UID)} />
      </TableCell>
    </TableRowMUI>
  );
};
